<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <g-form @submit="save">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body
              class="invoice-padding pt-0"
              style="margin-bottom: -30px;"
            >
              <b-row class="invoice-spacing">
                <!-- transaction date  -->
                <b-col
                  v-if="!currentBranch.setDefaultDate"
                  md="3"
                >
                  <g-field
                    :value="getDate(selectedItem.transactionDate)"
                    label-text="date"
                    disabled
                    name="date"
                  />
                </b-col>

                <b-col
                  v-else
                  md="3"
                >
                  <g-picker
                    :value.sync="selectedItem.transactionDate"
                    label-text="date"
                    name="date"
                  />
                </b-col>

                <!-- group type selection -->
                <b-col
                  class="gb-package"
                  md="5"
                >
                  <label> {{ $t("applyInvoiceForGroupOfStudents") }} </label>
                  <b-button-group>
                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'stage' },
                      ]"
                      variant="flat-warning"
                      @click="groupSelection('stage')"
                    >
                      {{ $t("stage") }}
                    </b-button>

                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'row' },
                      ]"
                      variant="flat-warning"
                      @click="groupSelection('row')"
                    >
                      {{ $t("row") }}
                    </b-button>

                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'class' },
                      ]"
                      variant="flat-warning"
                      @click="groupSelection('class')"
                    >
                      {{ $t("class") }}
                    </b-button>
                  </b-button-group>
                </b-col>

                <!-- group name  -->
                <b-col
                  v-if="selectedGroupName !== ''"
                  md="4"
                >
                  <b-form-group>
                    <g-field
                      field="select"
                      label="arabicName"
                      name="selectedGroup"
                      rules="required"
                      :label-text="selectedGroupName"
                      :options="selectedGroup"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :value.sync="selectedItem.groupValueId"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <b-row>
                <b-col cols="12">
                  <div class="d-none d-lg-flex">
                    <b-row class="flex-grow-1 px-2 mb-50">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("#") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t("notes") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                        class="service-title"
                      >
                        {{ $t("service") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t("Price") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("discounts") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("tax") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t("priceBeforetax") }}
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>
                </b-col>
              </b-row>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="p-50"
                        >
                          <p
                            class="mb-1 service-counter"
                          >
                            {{ index + 1 }}
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.notes"
                            :short-desc="true"
                            size="sm"
                            class="my-0"
                            maxlength="500"
                          />
                          <small class="textarea-counter-value float-right"><span class="char-count">{{
                            item.notes ? item.notes.length : 0
                          }}</span>
                            / 500
                          </small>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                          class="service-feature"
                        >
                          <g-field
                            field="select"
                            rules="required"
                            :clearable="false"
                            :label="isRight ? 'arabicName' : 'englishName'"
                            :value.sync="item.id"
                            :dir="isRight ? 'rtl' : 'ltr'"
                            :options="itemsData"
                            :placeholder="$t('selectService')"
                            @change="(val) => {
                              item.isTaxable = val.isTaxable;
                              item.code = val.code;
                              item.serviceId = val.id;
                              item.price = val.serviceFees;
                              item.net = val.serviceFees;
                              item.total = val.serviceFees;
                              item.lineSerial = index + 1;
                              computeTaxAndPriceBeforeTax(item);
                              item.tax = item.tax;
                              item.discountValue = val.discountValue;
                              item.discountPercentage = val.discountPercentage;
                              selectedItem.invoiceLines = invoiceData.items;
                              item.fixedPrecentage = val.isFeesFixed;
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.price"
                            type="number"
                            rules="required"
                            :disabled="item.fixedPrecentage === true"
                            class="mb-1"
                            @input="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({ item: item });
                            }
                            "
                            @change="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({ item: item });
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.discountValue"
                            type="number"
                            class="mb-1"
                            @input="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({
                                item: item,
                                discountValue: item.discountValue,
                              });
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.tax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.priceBeforeTax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                      </b-row>
                      <div class="d-flex align-items-center border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="TrashIcon"
                          class="cursor-pointer"
                          stroke="firebrick"
                          @click="removeItem(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItemInItemForm"
              >
                {{ $t("addService") }}
                <feather-icon
                  icon="CloudLightningIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>
            </b-card-body>
          </b-card>
        </g-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row>
            <b-modal
              ref="upload-modal"
              no-close-on-backdrop
              cancel-variant="outline-secondary"
              centered
              size="lg"
              hide-footer
              :title="$t('attachFile')"
            >
              <g-form>
                <b-row>
                  <b-col cols="12">
                    <div>
                      <input
                        type="file"
                        class="excel-upload-input"
                        accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
                        @change="handleFileUpload($event)"
                      >
                      <b-alert
                        v-if="file"
                        show
                        fade
                        class="mt-2 text-center"
                        variant="success"
                      >
                        <div class="alert-body">
                          <span>{{ $t("importSuccsefly") }} {{ file.name }}</span>
                        </div>
                      </b-alert>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      class="mx-1"
                      variant="primary"
                      @click="closeModal"
                    >
                      {{ $t("save") }}
                    </b-button>
                    <b-button
                      class="mx-1"
                      variant="danger"
                      data-action-type="delete"
                      :disabled="!file"
                      @click="
                        () => {
                          removeAttachment();
                        }
                      "
                    >
                      {{ $t("cancel") }}
                    </b-button>
                  </b-col>
                </b-row>
              </g-form>
            </b-modal>
          </b-row>
          <b-row class="m-auto">
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap">
                {{ $t("total") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap">
                {{ $t("discont") }}
              </span>
            </b-col>
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="
                  (v) => {
                    selectedItem.discountValue < 0 ||
                      selectedItem.discountValue > selectedItem.total
                      ? (v = 0)
                      : v;
                    selectedItem.discountValue = v;
                    selectedItem.discountPercentage = 0;
                    computeDiscount({ value: v });
                  }
                "
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                @input="
                  (v) => {
                    selectedItem.discountPercentage < 0 ||
                      selectedItem.discountPercentage > 100
                      ? (v = 0)
                      : v;
                    selectedItem.discountPercentage = v;
                    computeDiscount({ Percentage: v });
                  }
                "
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("netBeforeTax") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.netBeforeTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("tax") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.totalTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("net") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="!selectedItem.isPosted"
          class="border sticky-content-top2"
        >
          <b-row>
            <b-button
              v-permission="'addSalesTransactions'"
              :disabled="
                selectedItem.invoiceLines.length <= 0 ||
                  selectedItem.isPosted ||
                  isRequested
              "
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              @click="save"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-25"
              />
              {{ $t("save") }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- <invoice-sidebar-add-new-customer /> -->
  </section>
</template>
  <script>
// import Logo from '@core/layouts/components/Logo.vue';
import invoiceMixin from '@/mixin/invoiceMixin';
// import { ref, onUnmounted } from '@vue/composition-api';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import saveAs from 'file-saver';
// import store from '@/store';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BFormGroup,
  VBToggle,
} from 'bootstrap-vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition, invoiceMixin, reportMixin],
  props: ['id'],
  data() {
    return {
      selectedItem: {
        groupValueId: 0
      },
      discounts: [],
      stages: [],
      rows: [],
      classRooms: [],
      selectedGroup: [],
      selectedGroupName: '',
      invoiceType: 'Sales',
      isRequested: false,
      // posInvoiceDesign: posInvoiceDesign,
      template: '',
      file: '',
      currentLogo: localStorage.COMAPNY_LOGO,
      bankName: 0,
      banks: [],
      fullscreen: false,
      teleport: true,
      pageOnly: false,
      transactionType: 'sales',
      payemntType: 'Cash',
      paymentMethod: {
        value: null,
        cashPaid: 0,
        id: null,
        arabicName: '',
        englishName: '',
      },
      safeTransactions: {},
      customer: {},
      payment: {
        invoiceId: 0,
        lineSerial: 0,
        value: 0,
        paymentMethod: 'safe',
        safeId: 0,
        otherPaymentMethodId: 0,
        referenceNumber: 'string',
      },
      fromTransactionType: '',
      items: [],
      errors: [],
      pendingItem: {},
      pendingItems: [],
      isCalculatingDiscount: false,
      isCalculatingUnTaxableDiscount: false,
      checkPaymentSalesOrder: false,
      qrVal: '',
      currentUser: localStorage.USERNAME,
      itemsData: [],
      invoiceData: {
        items: [{
          itemArabicName: '',
          itemEnglishName: '',
          code: '',
          serviceId: '',
          quantity: 0,
          price: 0,
          total: 0,
          net: 0,
          fixedPrecentage: false,
          tax: 0,
          priceBeforeTax: 0,
          discountValue: 0,
          discountPercentage: 0
        }]
      }
    };
  },
  computed: {
    canAddNewPayment() {
      return (this.remaindered && this.paymentMethod.value > 0 && this.paymentMethod.value <= this.remaindered)
        && ((this.payemntType === 'Cash' && this.safeTransactions) || this.paymentMethod.otherPaymentMethodId);
    },
    isCash() {
      return this.selectedItem.paymentType === 'cash';
    },
    total() {
      return this.invoiceData.items.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
    },
    paid() {
      return this.selectedItem.invoicePayments.reduce((sum, item) => {
        // sum += parseFloat(item.cashPaid);
        item.totalValue = this.selectedItem.net
        if (item.paymentMethod === 'cash') {
          item.value = item.cashPaid
        }
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
    remaindered() {
      return this.fraction(this.selectedItem.net - this.paid) || 0;
    },
    optionsRadio() {
      return [
        { text: this.$t('cash'), value: 'Cash' },
        { text: this.$t('other'), value: 'PaymentMethods' },
      ];
    },
    tableColumnsInvoice() {
      return [
        { key: 'transactionDate', label: this.$t('transactionDate'), sortable: true },
        {
          key: 'paymentType',
          label: this.$t('paymentType'),
          sortable: true,
          isLocale: true,
        },
        {
          key: 'netBeforeTaxes',
          label: this.$t('netBeforeTax'),
          sortable: true,
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },
  watch: {
    fullscreen(newVal) {
      if (newVal === true) {
        document.getElementById('virticalmenu').classList.add('display');
        document.getElementById('navBar').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
        document.getElementById('navBar').classList.remove('display');
      }
    },
    'selectedItem.invoiceLines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0) this.selectedItem.invoicePayments = [];
    },
    remaindered(newVal) {
      this.paymentMethod.value = newVal;
      this.paymentMethod.cashPaid = newVal;
    },
    total(newval) {
      this.selectedItem.total = newval;
      this.computeTotals();
      if (this.selectedItem.invoicePayments.length && this.selectedItem.invoiceLines.length < 1) {
        this.selectedItem.invoicePayments = [];
      }
    },
    'selectedItem.total'(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.customer.discount) {
          this.computeDiscount({ Percentage: this.selectedItem.discountPercentage });
        } else {
          this.computeDiscount({ value: this.selectedItem.discountValue });
        }
      }
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.loadObj();
    this.selectedItem.transactionTime = this.getTime();
    this.selectedItem.branchId = this.branchId;
    this.itemsSearchProvider();
    // this.getBanks();
    this.hasClass();
    this.sourceId = this.selectedItem.sourceTransactionId;
    // if (!this.id) {
    this.updateViewModel();
    // }
    const id = this.id || this.selectedItem.sourceTransactionId;
    if (id > 0) {
      let transactionType = this.$route.query.sourceTransactionType;
      if (this.id) transactionType = this.transactionType;

      this.get({ url: `Invoices/${id}` }).then(
        (data) => {
          this.selectedItem = data;
          data.invoiceLines.forEach((item) => {
            item.id = item.serviceId;
          })
          this.invoiceData.items = data.invoiceLines;
          this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight);
            setTimeout(() => {
              this.$refs.form.style.overflow = null;
            }, 350);
          });
        }
      ).then(() => {
        if (this.sourceId > 0) this.prepareItemfromSource(transactionType);
      })
    }
    this.initTrHeight();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    validateApplyForGroup() {
      if (this.selectedGroupName === '') {
        this.doneAlert({ text: this.$t('youMustSelectGroup'), type: 'error' });
        return false;
      }
      return true;
    },

    groupSelection(groupName) {
      this.selectedGroupName = groupName;

      if (this.selectedGroupName === 'stage') {
        this.selectedGroup = this.stages;
        this.selectedItem.groupId = 1;
      } else if (this.selectedGroupName === 'row') {
        this.selectedGroup = this.rows;
        this.selectedItem.groupId = 2;
      } else if (this.selectedGroupName === 'class') {
        this.selectedGroup = this.classRooms;
        this.selectedItem.groupId = 3;
      } else {
        this.selectedGroup = []
        this.selectedItem.groupId = 0
      }

      if (this.selectedGroup.length > 0) {
        this.selectedItem.groupValueId = this.selectedGroup[0].id;
      }
    },
    loadObj() {
      this.getRows();
      this.getClassRoms();
      this.getStages();
    },
    getStages() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
    },

    getRows() {
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
    },

    getClassRoms() {
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classRooms = data;
      });
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file)
        .then((file1) => {
          this.selectedItem.imageBase64Content = file1.split(',').pop();
        });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
    },
    itemsSearchProvider() {
      this.get({ url: 'services' }).then((data) => {
        this.itemsData = data;
      })
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    addExtrnalItem() {
      var myWindow = window.open('/#/items/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          // this.refreshTable()
        }
      }, 500);
    },
    addExtrnalStudent() {
      var myWindow = window.open('/#/students/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          window.location.reload();
        }
      }, 500);
    },
    limit(event, limit) {
      if (this.selectedItem.notes.length >= limit) {
        event.preventDefault();
      }
    },
    hasClass() {
      if (this.fullscreen === true) {
        document.getElementById('virticalmenu').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
      }
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    toggleApi() {
      this.$fullscreen.toggle(this.$el.querySelector('.fullscreen-wrapper'), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
      })
    },
    prepareItemfromSource(sourceTransactionType) {
      this.fromTransactionType = sourceTransactionType;
      this.selectedItem.id = 0;
      this.selectedItem.transactionType = this.transactionType;
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.invoiceLines = this.selectedItem.invoiceLines.map((item) => {
        return {
          ...item,
          consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
          originalQuantity: item.quantity,
          quantity: item.quantity - (item.consumedQuantity || 0),
        };
      });
      this.selectedItem.invoiceLines = this.selectedItem.invoiceLines.filter((item) => { return item.quantity > 0; })
      // this.selectedItem.transactionTime = this.time;
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.code = null;
      this.selectedItem.sourceTransactionId = this.sourceId;
    },
    togglePendingItem() {
      if (this.selectedItem.invoiceLines && this.selectedItem.invoiceLines.length > 0) {
        const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
        this.pendingItems.push(pendingItem);
        this.doneAlert({
          title: this.$t('pendingSucess'),
          type: 'success',
        });
        this.getSelecteItemObj();
      } else {
        this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
      }
    },
    retriveInvoice(item) {
      this.pendingItems = this.pendingItems.filter((v) => v !== item);
      this.selectedItem = item;
      this.$refs['invoices-modal'].hide();
    },
    changeCustomer(v) {
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
      this.customer = v;
      this.selectedItem.discountPercentage = this.customer.discount;
      this.computeDiscount({ Percentage: this.selectedItem.discountPercentage })
      // this.computeDiscount({});
    },
    getSummary() {
      var totalPrice = 0;
      var totalDiscount = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.invoiceLines.forEach((item) => {
        this.selectedItem.discountValue += parseFloat(item.discountValue) || 0;
        totalPrice += item.total;
        totalDiscount += parseFloat(item.discountValue) || 0;
      });
      this.selectedItem.discountPercentage = ((totalDiscount / totalPrice) * 100).toFixed(2);
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = Number(item.price);
      item.net = Number(item.total);
      item.discountValue = Number(discountValue);
      if (discountPercentage > 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(2);
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue >= 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(2);
        item.discountValue = discountValue;
        item.net -= (discountValue || 0);
        if (this.selectedItem.discountValue <= 0) {
          this.selectedItem.discountValue = 0;
          this.selectedItem.discountValue = (discountValue || 0);
        } else {
          this.selectedItem.discountValue;
        }
      } else {
        item.net -= item.discountValue || 0;
        item.discountPercentage = ((item.discountValue / item.total) * 100).toFixed(2);
      }
      this.getSummary();
    },

    computeTaxAndPriceBeforeTax(item) {
      if (this.id > 0) {
        this.nationalityTaxable = this.selectedItem.studentTaxable;
      }
      this.itemTaxable = item.isTaxable;

      if (!this.nationalityTaxable) {
        if (item.isTaxable) {
          if (item.discountValue > 0) {
            item.priceBeforeTax = ((item.price - item.discountValue) / 1.15).toFixed(2);
            item.tax = ((item.price - item.discountValue) - ((item.price - item.discountValue) / 1.15)).toFixed(2);
          } else {
            item.priceBeforeTax = (item.price / 1.15).toFixed(2);
            item.tax = (item.price - (item.price / 1.15)).toFixed(2);
          }
        } else {
          item.tax = 0;
          if (item.discountValue > 0) {
            item.priceBeforeTax = (item.price - item.discountValue).toFixed(2);
          } else {
            item.priceBeforeTax = item.price;
          }
        }
      } else {
        this.studentTaxable = true;
        if (item.discountValue > 0) {
          item.priceBeforeTax = ((item.price - item.discountValue) / 1.15).toFixed(2);
          item.tax = ((item.price - item.discountValue) - ((item.price - item.discountValue) / 1.15)).toFixed(2);
        } else {
          item.priceBeforeTax = (item.price / 1.15).toFixed(2);
          item.tax = (item.price - (item.price / 1.15)).toFixed(2);
        }
      }
    },
    computeTotals() {
      if (this.id > 0) {
        this.selectedItem.nationalityTaxable = this.selectedItem.studentTaxable;
        if (this.selectedItem.totalTaxes > 0) {
          this.studentTaxable = true;
        } else {
          this.studentTaxable = false;
        }
      }
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.invoiceLines.forEach((invoice) => {
        if (!this.nationalityTaxable) {
          if (invoice.isTaxable) {
            this.selectedItem.netBeforeTaxes += (parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue));
          } else { this.selectedItem.netBeforeTaxes += invoice.priceBeforeTax; }
        } else { this.selectedItem.netBeforeTaxes += (parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue)); }
      });
      // get discounted
      this.computeTotalDiscount();
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.total = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.computeTotalTaxes();
      // this.selectedItem.netBeforeTaxes = this.fraction(Number(this.selectedItem.netBeforeTaxes));
      // this.selectedItem.net = this.fraction(this.selectedItem.net)
      this.selectedItem.totalTaxes = this.fraction(this.selectedItem.totalTaxes);
    },
    computeDiscount({ value, Percentage }) {
      if (!this.selectedItem.total) return;
      if (this.isCalculatingDiscount) return;
      this.isCalculatingDiscount = true;
      value = value || 0;
      Percentage = Percentage || 0;
      if (value) {
        this.selectedItem.discountPercentage = this.fraction((value / parseFloat(this.selectedItem.total)) * 100);
      } else {
        this.selectedItem.discountValue = this.fraction((parseFloat(this.selectedItem.total) * Percentage) / 100);
      }
      // compute totals after apply discount
      // this.computeTotals();
      this.isCalculatingDiscount = false;
    },

    getStudentsAccordingToSelectedGroup() {
      if (this.selectedGroupName === 'stage') {
        return this.students.filter((val) => val.stageId === this.selectedItem.groupValueId);
      }

      if (this.selectedGroupName === 'row') {
        return this.students.filter((val) => val.row === this.selectedItem.groupValueId);
      }

      if (this.selectedGroupName === 'class') {
        return this.students.filter((val) => val.classroomId === this.selectedItem.groupValueId);
      }
    },

    backToList() {
      this.$router.push({ name: 'invoices' });
    },
    save(typeInvoice) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      this.selectedItem.studentId = 0;
      if (!this.validateApplyForGroup()) return;

      const groupName = this.selectedGroup.find((val) => val.id === this.selectedItem.groupValueId).arabicName;

      this.get({ url: `Students/getStudentsCountByGroupId?group=${this.selectedItem.groupId}&id=${this.selectedItem.groupValueId}` }).then((outCount) => {
        if (outCount === 0) {
          this.doneAlert({ text: `${this.$t('noStudentIn')} ${groupName}`, type: 'info' });
          return;
        }

        if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      this.invoiceData.items.forEach(element => {
        if (!element.serviceId) {
          this.removeItem(element.lineSerial - 1, element)
        }
        this.selectedItem.invoiceLines = this.invoiceData.items
      });
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return
      }
      // if saveInvoiceWithRemainder
      if (this.isCash && this.remaindered > 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('you Dont Have Perm Save Invoice With Remainder'),
        });
        return;
      }
      this.isRequested = true
      const url = `Invoices/StudentInvoices/${this.invoiceType}`;
      (this.id > 0 ? this.update({ url, id: this.id, data: this.getModel() }).then(() => {
        this.$router.push({ name: 'invoices' });
      })
        : this.create({ url, data: this.getModel() }))
        .then((data) => {
          // if edit or from another source go to new after save
          if (typeInvoice === 'saveAndPrint') {
            this.print(data.id)
          }
          if (this.selectedItem.sourceTransactionId > 0) {
            this.$router.push({ name: 'invoices' });
          } else {
            this.getSelecteItemObj();
            this.invoiceData.items = [];
            this.addNewItemInItemForm();
            this.initTrHeight();
            this.selectedGroupName = ''
            this.file = ''
          }
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          this.backToList();
        }).catch(({ data }) => {
          this.doneAlert({ title: data.detail, type: 'error' });
        }).finally(() => {
          this.isRequested = false;
          this.getSelecteItemObj();
        })
      });
    },

    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.banks = data;
      });
    },
    print(id) {
      const printedItem = {
        id: id
      }
      this.printReport('Invoice-ar', printedItem);
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push(
        {
          itemArabicName: '',
          itemEnglishName: '',
          code: '',
          serviceId: '',
          quantity: 0,
          price: 0,
          total: 0,
          net: 0,
          notes: '',
          tax: 0,
          priceBeforeTax: 0
        }
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(iex, item) {
      this.invoiceData.items.splice(iex, 1);
      this.selectedItem.discountValue -= !item.discountValue ? 0 : item.discountValue;
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.invoiceData.items.length <= 0) {
        this.selectedItem.invoiceLines = [];
        this.selectedItem.discountValue = 0;
        this.selectedItem.discountPercentage = 0;
    }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
  </script>
  <style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>
  <style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.service-title {
  width: min-content;
  background-color: #918fdf;
  border: 1px solid #FFF;
  color: #FFF;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
}

.repeater-form .service-feature {
  padding-left: 3px;
  padding-right: 3px;
}

.repeater-form .service-counter {
  width: 20px;
  border: 1px solid;
  border-radius: 40%;
  text-align: center;
  background-color: antiquewhite;
  margin: 0 auto;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

.gb-package label {
  font-size: 14px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2);
}

@media (max-width: 768px) {
  .sticky-content-top span {
    margin-bottom: 10px;
  }
}
</style>
